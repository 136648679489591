import React, { useState } from 'react';
import './dialog.css'; // Import CSS for styling

const DialogBox = ({
  isOpen,
  onClose,
  onConfirm,
  text = <>Are you sure you want <b/> to proceed?</>, // Default dialog text
  okText = "OK", // Default OK button text
  cancelText = "" // Default Cancel button text
}) => {
  if (!isOpen) return null; // Don't render if dialog is closed

  return (
    <>
      {/* Overlay to close the dialog when clicking outside */}
      <div className="dialog-overlay" onClick={onClose}></div>

      {/* Dialog content */}
      <div className="dialog-box">
        <p className="dialog-text">{text}</p>
        <div style={{display: "flex", justifyContent: "center"}} className="dialog-buttons">
          <button style={{display: (cancelText == "" || cancelText == null) ? "none" : ""}}    onClick={onClose}>
            {cancelText}
          </button>
          <button onClick={onConfirm}>
            {okText}
          </button>
        </div>
      </div>
    </>
  );
};



export default DialogBox;
