import React, { useState } from 'react';
// import { FiLogOut, FiSettings } from 'react-icons/fi'; // Import icons
import './more_pop_menu.css'; // Import CSS
import {useNavigate, Link} from "react-router-dom";

const MorePopMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const navigate = useNavigate();

  function handleLogout () {
    localStorage.setItem('token', null);

    // Redirect or update UI as needed
    console.log('Logged out successfully:');

    navigate("/auth");
  }

  return (
    <div className="popup-container">
        <div className="popup-menu">
        <Link to="/settings" className="drawer-option">
          <i className="fa-solid fa-gear"></i>
          <span style={{ marginLeft: '10px' }}>Settings</span>
        </Link>
        <Link to="/subscription" className="drawer-option">
            <i className="fa-solid fa-credit-card"></i>
          <span style={{ marginLeft: '10px' }}>Subscription</span>
        </Link>
        <Link to="/contact" className="drawer-option">
          <i class="fa-solid fa-question"></i>
          <span style={{ marginLeft: '10px' }}>Contact Support</span>
        </Link>
        <div onClick={handleLogout} className="drawer-option">
          <i className="fa-solid fa-right-from-bracket"></i>
          <span style={{ marginLeft: '10px' }}>Logout</span>
        </div>
        </div>
    </div>
  );
};

export default MorePopMenu;
