// Filename - loader.js

import { TailSpin } from "react-loader-spinner";
const LoaderComp = ({isLoading = true}) => {
	return (
        <div style={{padding: '0 10px', display: isLoading ? 'inline' : 'none'}}>
            <TailSpin
			height="15"
			width="15"
			color="white"
			ariaLabel="tail-spin-loading"
			radius="1"
			wrapperStyle={{}}
			wrapperClass=""
			visible={isLoading}
		/>
        </div>
		
	);
}; 
export default LoaderComp;
