import React, { useState } from "react";
import "./styles.css";
import SignInForm from "./SignIn";
import SignUpForm from "./SignUp";
import AuthPage from "./Auth";
import ProfilePage from "./ProfilePage";
import Landing from "./landing_page/screens/Landing";
import UserView from "./UserView";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ErrorBoundary from './error_boundary';
import SettingsScreen from "./settings";
import SubscriptionScreen from "./subscription/subscription";
import Contact from "./landing_page/components/Sections/Contact";


export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>

      <Router>
      <Routes>
        <Route path="/auth" element={<AuthPage/>}/>
        <Route path="/signUp" element={<SignUpForm/>}/>
        <Route path="/signIn" element={<SignInForm/>}/>
        <Route path="/profile" element={<ProfilePage/>}/>
        <Route path="/settings" element={<SettingsScreen/>}/>
        <Route path="/subscription" element={<SubscriptionScreen/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/:user" element={<ProfilePage/>}/>
        <Route path="/" element={<Landing/>}/>
      </Routes>
      </Router>
    </>

  );
}
