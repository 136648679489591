import React from 'react';
import './settings.css'; // Import CSS for styling

const SettingsScreen = () => {
  return (
    <div className="settings-container">
      <h2 className="settings-header">Settings</h2>

      {/* Account Settings Section */}
      <div className="settings-section">
        <h3>Account Settings</h3>
        <div className="settings-item">
          <span>Change Password</span>
          <i className="fa-solid fa-chevron-right"></i>
        </div>
        <div className="settings-item">
          <span>Email Notifications</span>
          <input type="checkbox" />
        </div>
        {/* <div className="settings-item">
          <span>Manage Email</span>
          <i className="fa-solid fa-chevron-right"></i>
        </div> */}
      </div>

      {/* Notification Settings Section */}
      {/* <div className="settings-section">
        <h3>Notifications</h3>
        <div className="settings-item">
          <span>Push Notifications</span>
          <input type="checkbox" />
        </div>
        <div className="settings-item">
          <span>Email Notifications</span>
          <input type="checkbox" />
        </div>
      </div> */}

      {/* App Preferences Section */}
      {/* <div className="settings-section">
        <h3>App Preferences</h3>
        <div className="settings-item">
          <span>Language</span>
          <i className="fa-solid fa-chevron-right"></i>
        </div>
        <div className="settings-item">
          <span>Dark Mode</span>
          <input type="checkbox" />
        </div>
      </div> */}
    </div>
  );
};

export default SettingsScreen;
