import React, {useState, useEffect} from 'react';
import SignInForm from "./SignIn";
import SignUpForm from "./SignUp";
import "./styles.css"
import { useLocation } from 'react-router-dom';


const AuthPage = () => {
    const [type, setType] = useState("signIn");

    const routerLocation = useLocation();
    // const data = routerLocation.state || {};

    useEffect(() => {

      if(routerLocation.state) {
        console.log("Router location state is defined. sEtting authentication type");
        console.log(`type: ${routerLocation.state.page}`)
        setType(routerLocation.state.page);

      } else {
        console.log("Router location state is not defined");
      }
      
        // Optional cleanup function
        return () => {
          // Cleanup code here
        };
      }, []);

    const handleOnClick = text => {
      if (text !== type) {
        setType(text);
        return;
      }
    };
    const containerClass = "container " + (type === "signUp" ? "right-panel-active" : "left-panel-active");

    return <div className="App">
    <div className={containerClass} id="container">
    {/* <ProfilePage/> */}
    <SignUpForm />
    <SignInForm />
    {/* <div className="overlay-container hide-on-mobile">
      <div className='overlay'>
        <div className="overlay-panel overlay-left">
          <h1>Welcome Back!</h1>
          <p>
            To keep connected with us please login with your personal info
          </p>
          <button
            className="ghost"
            id="signIn"
            onClick={() => handleOnClick("signIn")}
          >
            Sign In
          </button>
        </div>
        <div className="overlay-panel overlay-right hide-on-mobile">
          <h1>Hello, Friend!</h1>
          <p>Enter your personal details and start journey with us</p>
          <button
            className="ghost "
            id="signUp"
            onClick={() => handleOnClick("signUp")}
          >
            Sign Up
          </button>
        </div>
      </div>
    </div> */}
</div>

  </div>;
}

// const AuthPage = () => {
  
//   return  <body>
//     <h1>Hey there!</h1>
//     {/* <SignUpForm /> */}
//   </body>
// }



export default AuthPage;