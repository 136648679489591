import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import styled from 'styled-components';

const UrgeWithPleasureComponent = ({remain}) => (
  <CountdownCircleTimer
    true
    duration={3}
    initialRemainingTime={remain}
    colors="#A30000"
  />
)

const video = styled.video`
  width: 100%;
  max-width: 600px;

  @media (min-width: 768px) {
    width: 60%;
    margin: 0 auto;
    display: block;
  }
`;

const VideoRecorder = forwardRef((props, ref) => {
  const {videoFile, setVideoTranscript, setVideoFile, foregroundColor, readOnly} = props;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [recording, setRecording] = useState(false);
  const [showFeed, setShowFeed] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const mediaRecorderRef = useRef(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [count, setCount] = useState(0);

  async function countDown() {
    for (let i = 3; i > 0; i--) {
      setCount(i); // Execute the action
      if (i > 0) {
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for interval
      }
    }
  }

  useImperativeHandle(
    ref,
    () => ({
      extSetVideoUrl : (url) => {
        setVideoURL(url);
      }
    })
  );

  const startRecording = async () => {

    setIsCountingDown(true);
    await countDown()
    setIsCountingDown(false);

    setShowFeed(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true , audio: true});
      streamRef.current = stream;
      videoRef.current.srcObject = stream;
      videoRef.current.play();

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks = [];
      mediaRecorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'video/webm' });
        const url = URL.createObjectURL(blob);
        const videoFile = new File([blob], `recorded_video_${Date.now()}_${Math.floor(Math.random() * 10000)}.webm`
        , { type: 'video/webm' });
        setVideoFile(videoFile); //Set video file in the parent component.
        setVideoURL(url);
      };

      mediaRecorder.start();
      setRecording(true);
      
      // Stop the recording automatically after 2 minutes (120000ms)
      setTimeout(() => stopRecording(), 120000);

    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  const stopRecording = () => {
    setShowFeed(false);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }
    setRecording(false);
  };

  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{display: "relative"}}>
      <div style={{display: isCountingDown == true ? "flex" : "none", width: "100%" , justifyContent: "center", margin: "100px 0"}} >
          <div style={{fontSize: "50px", color: foregroundColor}}>
            {count}
          </div>
      </div>

      <div style={{display: isCountingDown == true ? "none" : "flex", justifyContent: "center"}}>
        <video ref={videoRef} style={{display: showFeed ? "block" : "none", transform: "scaleX(-1)",}} width={isDesktop ? '50%' : '100%'} max-width="600px" autoPlay muted />
        {videoURL && (<video src={videoURL} style={{display: showFeed ? "none" : "block"}} controls width={isDesktop ? '50%' : '100%'} max-width="600px" />)}
      </div>

      {/* {showFeed ? <video ref={videoRef}  width="320" height="240" autoPlay muted /> : <video src={videoURL} controls width="320" height="240" />} */}
      {/* <video ref={videoRef}  width="320" height="240" autoPlay muted /> */}
      <div style={{width: "100%", display: 'flex'}}>

        {/* Record button */}
         <div style={{display: readOnly ? "none" : "block", margin:"10px auto"}}>
          {!recording ? (
                <button onClick={startRecording} style={{backgroundColor: foregroundColor}} >Start Recording</button>
            ) : (
                 <button onClick={stopRecording} style={{backgroundColor: foregroundColor}}>Stop Recording</button>
            )}
        </div>

      </div>
      {/* {videoURL && (
        <div>
          <video src={videoURL} style={{display: showFeed ? "none" : "block"}} controls width="320" height="240" />
          <a href={videoURL} download="recording.webm">{showFeed ? "showFeed" : "Don't show feed"}</a>
        </div>
      )} */}
    </div>
  );
})

export default VideoRecorder;
