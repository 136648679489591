import React, { useState, useEffect } from 'react';
import './subscription.css'; // Import CSS for styling
import SubscriptionCard from './subscription_card';
import Loader from '../loader';

const SubscriptionScreen = () => {

  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentPlan, setCurrentPlan] = useState('Premium');
  const [subscriptionDate, setSubscriptionDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [transactions, setTransactions] = useState([]);

  // isSubscribed = false, 
  // currentPlan = 'Premium', 
  // subscriptionDate = '2024-09-15', 
  // expirationDate = '2024-10-30', 
  // transactions = [] 

  useEffect(() => {
        const token = localStorage.getItem('token');

        fetch(`https://api.yourtalentid.com/api/user/get-data`, {
            method: 'GET', // or 'POST', etc.
            headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(userData => {

            if(!userData) {
                console.log("***USER DATA IS NULL***");
            }

            console.log("Successfully got user-data");
            if(userData.active_offer != null && userData.active_offer != "") {
              const subsDate = new Date(userData.subs_start);
              const expDate= new Date(userData.subs_end);
              console.log(`${userData.subs_start}`)
              setIsSubscribed(true);
              setCurrentPlan(userData.active_offer);
              setSubscriptionDate(subsDate.toLocaleDateString());
              setExpirationDate(expDate.toLocaleDateString());
            }

            // setFullName(userData.name); // Uncomment if needed

        }).catch(err => {
            console.log("Failed to fetch user data", err);
            // setError("Error fetching data"); // Uncomment if needed
            // setLoading(false); // Uncomment if needed
        });

  }, []);

  const handleCancel = () => alert('Subscription canceled.');

  const handleSubscribe = async() => {
    setIsSubscribing(true);
    const offerId = 0;
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('https://api.yourtalentid.com/api/payment/create-checkout-session', {
      // const response = await fetch('http://localhost:5000/api/payment/create-checkout-session', {  
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({offerId}),
      });


      try {
        const htmlContent = await response.text();
  
        // Create a Blob from the HTML content
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
  
        // Open the Blob URL in a new tab
        window.open(url, '_blank');
        
        // Optionally, revoke the object URL after a timeout to free up memory
        setTimeout(() => URL.revokeObjectURL(url), 1000);
      } catch (error) {
        console.error('Error opening checkout:', error);
      }

      // const data = await response.json();

      // if (response.ok) {

      // } else {
      //   console.error('Subscription failed:', data.message);
      // }

    }catch(e) {
      console.log(`Error: ${e.message}`);
    }
    setIsSubscribing(false);
  };

  const [isSubscribing, setIsSubscribing] = useState(false)

  return (
    <div className="subscription-container">
      <h1 className="subscription-title">Subscription</h1>
      <SubscriptionCard/>

      {isSubscribed ? (
        <>
          <p className="subscription-text">
            You are currently subscribed to the <strong>{currentPlan}</strong> plan.
          </p>
          <p className="subscription-detail">
            <span>Subscription Date: </span>{subscriptionDate}
          </p>
          <p className="subscription-detail expiration">
            <span>Expiration Date: </span>{expirationDate}
          </p>

          <button className="cancel-btn" onClick={handleCancel}>
            Cancel Plan
          </button>

          {/* <div className="transaction-history">
            <h2 className="transaction-title">Transaction History</h2>
            {transactions.length > 0 ? (
              transactions.map((transaction, index) => (
                <div key={index} className="transaction-card">
                  <p><strong>Date:</strong> {transaction.date}</p>
                  <p><strong>Amount:</strong> {transaction.amount}</p>
                  <p><strong>Status:</strong> {transaction.status}</p>
                </div>
              ))
            ) : (
              <p className="no-transactions">No transactions available.</p>
            )}
          </div> */}
        </>
      ) : (
        <>
          <p className="no-plan-text">No active plan</p>
          {/* <button  onClick={handleSubscribe}>
            Subscribe
          </button> */}
          <div style={{display: "flex" , width: "100%", margin: "40px auto", justifyContent: "center"}}>
            <button onClick={handleSubscribe} style={{ backgroundColor: "black", display: "flex" }}>
                <div>Subscribe</div>
                <Loader isLoading={isSubscribing} />
            </button>
          </div>

        </>
      )}
    </div>
  );
};



export default SubscriptionScreen;
