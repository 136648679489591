import React from "react";
import {useNavigate} from "react-router-dom";
import Loader from "./loader";
import Logo from "./logo"

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function SignUpForm() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    email: "",
    password: "",
    first_name: "",
    last_name: ""
  });

  const [isLoading, setLoading] = React.useState(false);

  const startLoading = () => {
      setLoading(true);
      console.log(`Starting to load: ${isLoading}`)
  }

  const stopLoading = () => {
    setLoading(false);
    console.log(`stopping to load: ${isLoading}`)
  }



  const handleChange = evt => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  };

  const handleOnSubmit = evt => {
    evt.preventDefault();

    const { name, email, password } = state;
    alert(
      `You are sign up with name: ${name} email: ${email} and password: ${password}`
    );

    for (const key in state) {
      setState({
        ...state,
        [key]: ""
      });
    }
  };

  const gotoSignInPage = () => {
    const data = { page: "signIn" }
    navigate('/auth', { state: data });
    window.location.reload();
  };

  const handleRegister = async (e) => {
    localStorage.setItem('autoNavigateToSubsPage', true);
    e.preventDefault();
    const email = state.email;
    const password = state.password;
    const first_name = state.first_name;
    const last_name = state.last_name;
    
    startLoading();
    // await delay(3000);
    try {
      const response = await fetch('https://api.yourtalentid.com/api/auth/register', {
      // const response = await fetch('http://localhost:5000/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, first_name, last_name }),
      });

      const data = await response.json();

      if (response.ok) {

        /// send the welcome email
        const response = await fetch('https://api.yourtalentid.com/api/send_welcome_email', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ first_name, email }),
        });

        if(response.ok) {
          console.log("successfully sent welcome email");
        } else {
          console.log("Failed to send welcome email");
        }

        // Store the JWT token in localStorage or sessionStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('token_created', Date.now())

        // Redirect or update UI as needed
        console.log('Logged in successfully:', data.token);
        localStorage.setItem("just_signed_up", true)
        navigate("/profile");
      } else {
        console.error('Login failed:', data.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    stopLoading();
  };

  return (
    <div className="form-container sign-up-container">
      
      <form onSubmit={handleRegister}>
        <Logo/>
        <h1>Create Your Account</h1>
        {/* <div className="social-container">
          <a href="#" className="social">
            <i className="fab fa-facebook-f" />
          </a>
          <a href="#" className="social">
            <i className="fab fa-google-plus-g" />
          </a>
          <a href="#" className="social">
            <i className="fab fa-linkedin-in" />
          </a>
        </div> */}
        <span style={{margin: "15px auto"}}>Start building your professional profile today. Sign up below.</span>
        <input
          type="text"
          name="first_name"
          value={state.first_name}
          onChange={handleChange}
          placeholder="First Name"
        />
        <input
          type="text"
          name="last_name"
          value={state.last_name}
          onChange={handleChange}
          placeholder="Last Name"
        />
        <input
          type="email"
          name="email"
          value={state.email}
          onChange={handleChange}
          placeholder="Email"
        />
        <input
          type="password"
          name="password"
          value={state.password}
          onChange={handleChange}
          placeholder="Password"
        />
        <button style={{margin: "15px auto"}} onClick={handleRegister}>
          <div style={{display: "flex"}}>
            <div>
              Sign Up
            </div>
            <Loader isLoading={isLoading}/>
          </div>
          </button>
        <div
            className=" hover-underline"
            // id="signUp"
            onClick={gotoSignInPage} style={{color: "blue"}}
          >
            Sign In
          </div>
      </form>
      
    </div>
  );
}

export default SignUpForm;
