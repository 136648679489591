import React, { useState } from 'react';
import './drawer.css'; // Import CSS for styling
import Logo from './logo';
import {useNavigate, Link} from "react-router-dom";


const DrawerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => setIsOpen(!isOpen);

  const navigate = useNavigate();

  function handleLogout () {
    localStorage.setItem('token', null);

    // Redirect or update UI as needed
    console.log('Logged out successfully:');

    navigate("/auth");
  }

  return (
    <>
      {/* Button to open the drawer */}
      {/* <button className="open-drawer-btn hide-on-desktop" onClick={toggleDrawer}>
            <i class="fa-solid fa-bars"></i>
      </button> */}
      <div style={{display: 'flex', justifyContent: 'start'}} onClick={toggleDrawer}>
                <div className='hide-on-desktop'>
                    <i class="fa-solid fa-bars"></i>
                </div>
            </div>

      {/* Overlay to close the drawer when clicking outside */}
      {isOpen && <div className="drawer-overlay" onClick={toggleDrawer}></div>}

      {/* Drawer content */}
      <div className={`drawer ${isOpen ? 'open' : ''}`}>

        <div style={{display: 'flex', justifyContent: 'center', width: '100%', margin: "0 auto"}} className="drawer-header">
          <Logo/>
          {/* <button className="close-drawer-btn" onClick={toggleDrawer}>
            <i className="fa-solid fa-xmark"></i>
          </button> */}
        </div>
        <Link to="/settings" className="drawer-option">
          <i className="fa-solid fa-gear"></i>
          <span style={{ marginLeft: '10px' }}>Settings</span>
        </Link>
        <Link to="/subscription" className="drawer-option">
            <i class="fa-solid fa-credit-card"></i>
          <span style={{ marginLeft: '10px' }}>Subscription</span>
        </Link>
        <Link to="/contact" className="drawer-option">
          <i class="fa-solid fa-question"></i>
          <span style={{ marginLeft: '10px' }}>Contact Support</span>
        </Link>
        <div onClick={handleLogout} className="drawer-option">
          <i className="fa-solid fa-right-from-bracket"></i>
          <span style={{ marginLeft: '10px' }}>Logout</span>
        </div>
      </div>
    </>
  );
};

export default DrawerMenu;
